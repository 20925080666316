


export const CREATION_FORM_TYPE = 1
export const MODIFY_FORM_TYPE = 2
export const ACTIVE_STATUS = 1  // estado ACTIVADO de un formulario
export const CORRECTION_STATUS = 2  // estado CORRECCION de un formulario
export const IN_PROGRESS_STATUS  = 3  // estado EN PROGRESO de un formulario
export const COMPLETED_STATUS = 7  // estado FINALIZADO de un formulario
export const CANCELED_STATUS = 8  // estado CANCELADO de un formulario
export const VOIDED_STATUS = 9  // estado ANULADO de un formulario


export const FORM_TASK_CREATE = 1  // crear acceso aplicacion
export const FORM_TASK_ADD = 2 // agregar acceso aplicacion
export const FORM_TASK_REMOVE = 3 // quitar acceso aplicacion
export const FORM_TASK_OFFICE_UPDATE = 4 // actualizar/modificar oficina


