<template>

    <div>

        <b-button
                variant="primary"
                @click="handleShowModal"
        >
            <span class="text-nowrap">Nuevo formulario</span>
        </b-button>

        <b-modal
                title="Nuevo formulario Modificación"
                v-model="showModal"
                no-close-on-backdrop
                centered
                @hidden="resetModal"
        >

            <b-form-group label="Colaborador">
                <v-select
                        v-model="selectedColaborador"
                        label="fullName"
                        :options="colaboradoresList"
                        :reduce="option => option.empId"
                />
            </b-form-group>


            <b-form-group label="Fecha">
                <flat-pickr
                        v-model="fechaSolicitud"
                        class="form-control"
                        :config="configDate"
                />
            </b-form-group>
            <template #modal-footer>
                <div class="w-100 text-right">
                    <b-button variant="secondary" @click="handleCancelModal" >
                        Cancelar
                    </b-button>
                    <b-button
                            variant="primary"
                            class="ml-1"
                            @click="handleCreate"
                            :disabled="isBusy || hasSomeNullEmptyItem"
                    >
                        <b-spinner small v-if="isBusy"/>
                        Crear formulario
                    </b-button>
                </div>
            </template>


        </b-modal>

    </div>

</template>

<script>
import {BButton, BFormGroup, BModal, BSpinner} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/es.js"
import moment from 'moment'
import {mapActions} from "vuex";
import { MODIFY_FORM_TYPE} from "@/modules/common/constants";
import { getUserData } from '@/modules/auth/utils'

export default {
    name: "SolicitudModificacionModalNew.vue",
    props: ['formType'],
    components: {BSpinner, vSelect, BModal, BButton, BFormGroup,flatPickr},
    data(){
        return {
            isBusy: false,
            showModal: false,
            colaboradoresList: [],
            selectedColaborador: null,
            fechaSolicitud: moment().format('YYYY-MM-DD'),
            configDate: {
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: "F j, Y",
                locale: Spanish,
            },
        }
    },
    computed: {
        hasSomeNullEmptyItem(){
            return this.selectedColaborador === null || this.fechaSolicitud === ''
        }
    },
    methods: {

       ...mapActions('solicitante-solicitudes-module', ['createFormularioCreacion']),

       ...mapActions('empleado-module', ['listEmpleados']),

        handleCancelModal() {
            this.showModal = false
        },
        handleShowModal() {
            this.showModal = true
        },
        resetModal(){

          this.selectedColaborador = null
          this.fechaSolicitud =  moment().format('YYYY-MM-DD')

        },

        async handleCreate(){
            try {
                this.isBusy = true
                const user = getUserData()

              const formData = {
                    ftyId: this.formType,
                    empId: this.selectedColaborador,
                    frmFecha: this.fechaSolicitud,
                    userId: user.id
                }

                const data = await this.createFormularioCreacion(formData)


                this.showModal = false
                this.isBusy = false
                this.$router.push({name: 'solicitudes-modificacion-accesos', query: { key: data.frmUuid }})
            } catch (error) {
                console.log(error)
            } finally {
                this.isBusy = false
            }
        },
        async initializeReferences() {

          //this.colaboradoresList = await this.listEmpleados({ 'tipo': MODIFY_FORM_TYPE })

          const colaboradores = await this.listEmpleados()


          this.colaboradoresList = colaboradores.map( employee => ({
                empId: employee.empId,
                empNombres: employee.empNombres,
                empApellidos: employee.empApellidos,
                fullName: `${employee.empNombres} ${employee.empApellidos}`
              })
          )

        }
    },
    async created() {
        await this.initializeReferences()
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
